import React, { useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { WindowLocation } from '@reach/router';

import { Logo, Square } from "./utils"
import List from "./navigation-list"
import { Menu } from "react-feather"
import SideBar from "./sidebar"
import { NavigationQuery } from "./__generated__/NavigationQuery"
import { Theme } from "./layout"
import SocialLinks from "../utils/sociallinks"

type NavbarProps = { location: WindowLocation, currentTheme: number, switchTheme: () => void, themes: Theme[], allowThemeSwitch: boolean, front: boolean };
const Navbar: React.FC<NavbarProps> = ({ location, currentTheme, switchTheme, themes, allowThemeSwitch = true, front }) => {
    const currentLocation = location.pathname.split("/")[1]

    const data = useStaticQuery<NavigationQuery>(graphql`
        query NavigationQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    const navbar = useRef(null)
    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <React.Fragment>
            <div
                className={`sticky top-0 duration-300 transition-all z-20 nav`}
                ref={navbar}
            >
                {/* PC */}
                <div className="h-screen pt-12 pb-12 hidden lg:flex flex-col justify-between">
                    <div className="text-center">
                        <Link to="/" title={data.site.siteMetadata.title} className="">
                            <Square>
                                <Logo className="w-full h-full "></Logo>
                            </Square>
                        </Link>
                    </div>
                    <div className=" text-center">
                        <List name="sidebar-nav" current={currentLocation} currentTheme={currentTheme} switchTheme={switchTheme} themes={themes} withThemeSwitch={allowThemeSwitch} liClassName="block my-2" />

                        <div className="flex w-full">
                            <div className=" mx-auto">
                                <SocialLinks />
                            </div>
                        </div>
                    </div>
                </div>

                {/* 線 */}
                <div className="absolute line h-full w-px right-0 top-0 bg-gradient-primary"></div>


                {/* スマホ メニューボタン */}
                <button
                    className="absolute top-0 left-0 text-primary outline-0 lg:hidden pt-16 pl-3"
                    style={{
                        transform: "translateY(-50%)",
                        top: "50%",
                        left: "10px",
                    }}
                    onClick={() => {
                        setSidebarOpen(true)
                    }}
                >
                    <Menu />
                </button>

                {/* スマホ */}
                <SideBar open={sidebarOpen} onChange={setSidebarOpen}>
                    <div className="bg-bg h-full pb-8 flex flex-col justify-between ">
                        <div style={{
                            width: '30vh',
                            height: '30vh'
                        }}
                        className="top-0 mt-4 mx-auto ">
                            <Link to="/" title={data.site.siteMetadata.title} className="">
                                <Square>
                                    <Logo/>
                                </Square>
                            </Link>
                        </div>
                        <div className="h-auto text-center" >
                            <List name="sidebar-nav" current={currentLocation} currentTheme={currentTheme} switchTheme={switchTheme} themes={themes} withThemeSwitch={allowThemeSwitch} liClassName="block my-2" />

                            <div className="flex w-full">
                                <div className=" mx-auto">
                                    <SocialLinks />
                                </div>
                            </div>
                        </div>

                    </div>
                </SideBar>


            </div>

        </React.Fragment>
    )
}

export default Navbar

import React from "react"

import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby"
import { LogoQuery } from "./__generated__/LogoQuery"

const Logo = ({ className = "w-full h-full" }) => {
    const data = useStaticQuery<LogoQuery>(graphql`
        query LogoQuery {
            site {
                siteMetadata {
                    title
                    logo
                }
            }
        }
    `)

    return (
        <div
            className={className}
            style={{
                backgroundImage: `url(${data.site.siteMetadata.logo})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
            }}
        />
    )
}

const Square = ({children}) => {
    return (
      <div style={{
        paddingTop: "100%",
        position: "relative",
      }}>
        <div style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}>
          {children}
        </div>
      </div >
    )
  }

const Link = props => {
    if (props.to) {
        if (props.to.startsWith("/")) {
            return <GatsbyLink {...props}>{props.children}</GatsbyLink>
        }

        return <a href={props.to} target="_blank" className="border-b">{props.children}</a>
    } else {
        return (
            <button {...props}></button>
        )
    }
}

export { Logo, Link, Square }

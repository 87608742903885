import React from "react"
import { useStaticQuery, graphql, } from "gatsby"
import { NavigationListQuery } from "./__generated__/NavigationListQuery"
import { Theme } from "./layout"
import { Link } from "../components/utils"

type NavigationListProps = { name?: string, className?: string, liClassName?: string, current?: string, withThemeSwitch?: boolean, currentTheme?: number, switchTheme?: () => void, themes?: Theme[] }
const List: React.FC<NavigationListProps> = ({
    name,
    className = "",
    liClassName = "",
    current,
    withThemeSwitch = true,
    currentTheme,
    switchTheme,
    themes,
}) => {
    const data = useStaticQuery<NavigationListQuery>(graphql`
        query NavigationListQuery {
            allMicrocmsNav {
                edges {
                  node {
                      name
                      link
                  }
                }
            }
        }
    `)
    const items = data.allMicrocmsNav.edges;
    const list = items.map((e, i) => (
        <ListItem
            key={`navigation-${name}-${i}`}
            data={e.node}
            active={`/${current}` === e.node.link}
            liClassName={liClassName}
        />
    ))

    if (withThemeSwitch) {
        const themeButtons = themes.map((item, i) => {
            const next = i !== themes.length - 1 ? i + 1 : 0
            return (
                <button
                    className={`text-color-2 transition-transform duration-200 transform top-0 left-0 ${
                        i === currentTheme ? "scale-100" : "scale-0 absolute"
                        }`}
                    title={`Switch to ${themes[next].label}`}
                    key={`${name}-theme-switch-btn-${item.name}`}
                    onClick={switchTheme}
                >
                    {item.icon}
                </button>
            )
        })
        // ダークモード切り替え
        // list.push(
        //     <li
        //         className="theme-switcher"
        //         key={`${name}-theme-switcher relative`}
        //     >
        //         {themeButtons}
        //     </li>
        // )
    }

    return <ul className={className}>{list}</ul>
}

const ListItem = ({ data, active, liClassName }) => {
    return (
        <li className={`${liClassName} ${active ? "active" : ""}`}>
            <Link to={data.link} title={data.name} className="text-color-2 focus:text-primary">
                <span>{data.name}</span>
            </Link>
        </li>
    )
}

export default List
